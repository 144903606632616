const dev = {
  api: {
    invokeUrl: "https://2ytguhpow8.execute-api.ap-south-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "ap-south-1",
    USER_POOL_ID: "ap-south-1_17zpAdh4V",
    APP_CLIENT_ID: "qajohiih0legea4176b8cashl",
  },
};

const prod = {
  api: {
    invokeUrl: "https://jldv8wi33a.execute-api.ap-south-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "ap-south-1",
    USER_POOL_ID: "ap-south-1_WWpoOPg52",
    APP_CLIENT_ID: "5d10feh3r6quc53vbkjq3ugqjf",
  },
};

let config = dev;
if (process.env.REACT_APP_STAGE) {
  switch (process.env.REACT_APP_STAGE.trim()) {
    case "dev":
      config = dev;
      break;
    case "test":
      config = test;
      break;
    case "prod":
      config = prod;
      break;
    default:
      config = dev;
      break;
  }
}

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
