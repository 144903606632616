import React from 'react';
import { connect } from 'react-redux';
import { timeFormat } from '../../common/CommonFunctions';
import Button from '@material-ui/core/Button';
import {
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const useStyles = makeStyles((theme) => ({
    Textarea: {
      background: '#fff',
      border: '1px solid #ccc',
      padding: 15,
      width: '100%',
      height: 'calc(100% - 50px) !important',
      overflowY: 'scroll !important',
      minHeight: 350,
    }
  }));

function ConfigDetails(props) {
  const { configDetails, closeDetails } = props;
  const classes = useStyles();

  return (
    <div className="detailsDrawer">
      <Button onClick={() => { closeDetails() }} variant="contained" className='detailsCloseBtn'>
        Close
      </Button>
      <h2>{configDetails.key}</h2>
      <div className='split alignStart'>
        <div>
          <h3>Json view</h3>
          <TextareaAutosize
            name="config"
            className={classes.Textarea}
            value={JSON.stringify(configDetails.config, null, 2)}
          />
        </div>
        <div>
          <h3>Graphical view</h3>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
    permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigDetails);