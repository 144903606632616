export const RESET_STATE = "RESET_STATE";

export const SET_ALL_PERMISSIONS = "SET_ALL_PERMISSIONS";
export const PERMISSIONS_HAS_ERRORED = "PERMISSIONS_HAS_ERRORED";
export const SET_ACTIVE_TEAMID = "SET_ACTIVE_TEAMID";
export const SET_ACTIVE_OUID = "SET_ACTIVE_OUID";
export const SET_USER_ROLES = "SET_USER_ROLES";
export const SET_ACTIVE_OUID_CHAIN = "SET_ACTIVE_OUID_CHAIN";
export const CLEAR_ACTIVE_OUID_CHAIN = "CLEAR_ACTIVE_OUID_CHAIN";

export const LOGIN_REQUSTED = "LOGIN_REQUSTED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const JWT_SET = "JWT_SET";
export const UN_JWT_SET = "UN_JWT_SET";
export const JWT_ERRORED = "JWT_ERRORED";

export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";

export const GET_MY_PROFILE_DETAILS_REQUESTED =
  "GET_MY_PROFILE_DETAILS_REQUESTED";
export const GET_MY_PROFILE_DETAILS_SUCCESS = "GET_MY_PROFILE_DETAILS_SUCCESS";
export const GET_MY_PROFILE_DETAILS_ERRORED = "GET_MY_PROFILE_DETAILS_ERRORED";

export const GET_ALL_ORGUSER_REQUESTED = "GET_ALL_ORGUSER_REQUESTED";
export const GET_ALL_ORGUSER_SUCCESS = "GET_ALL_ORGUSER_SUCCESS";
export const GET_ALL_ORGUSER_ERRORED = "GET_ALL_ORGUSER_ERRORED";

export const GET_ORG_USER_DETAILS_REQUESTED = "GET_ORG_USER_DETAILS_REQUESTED";
export const GET_ORG_USER_DETAILS_SUCCESS = "GET_ORG_USER_DETAILS_SUCCESS";
export const GET_ORG_USER_DETAILS_ERRORED = "GET_ORG_USER_DETAILS_ERRORED";

export const UPDATE_ORG_USER_REQUESTED = "UPDATE_ORG_USER_REQUESTED";
export const UPDATE_ORG_USER_SUCCESS = "UPDATE_ORG_USER_SUCCESS";
export const UPDATE_ORG_USER_ERRORED = "UPDATE_ORG_USER_ERRORED";

export const CREATE_ORG_USER_REQUESTED = "CREATE_ORG_USER_REQUESTED";
export const CREATE_ORG_USER_SUCCESS = "CREATE_ORG_USER_SUCCESS";
export const CREATE_ORG_USER_ERRORED = "CREATE_ORG_USER_ERRORED";

export const CREATE_USER_PROFILE_REQUESTED = "CREATE_USER_PROFILE_REQUESTED";
export const CREATE_USER_PROFILE_SUCCESS = "CREATE_USER_PROFILE_SUCCESS";
export const CREATE_USER_PROFILE_ERRORED = "CREATE_USER_PROFILE_ERRORED";

export const UPDATE_USER_PROFILE_REQUESTED = "UPDATE_USER_PROFILE_REQUESTED";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_ERRORED = "UPDATE_USER_PROFILE_ERRORED";

export const GET_USER_PROFILE_DETAILS_REQUESTED =
  "GET_USER_PROFILE_DETAILS_REQUESTED";
export const GET_USER_PROFILE_DETAILS_SUCCESS =
  "GET_USER_PROFILE_DETAILS_SUCCESS";
export const GET_USER_PROFILE_DETAILS_ERRORED =
  "GET_USER_PROFILE_DETAILS_ERRORED";

export const GET_ALL_USER_PROFILES_REQUESTED =
  "GET_ALL_USER_PROFILES_REQUESTED";
export const GET_ALL_USER_PROFILES_SUCCESS = "GET_ALL_USER_PROFILES_SUCCESS";
export const GET_ALL_USER_PROFILES_ERRORED = "GET_ALL_USER_PROFILES_ERRORED";

export const DELETE_USER_PROFILE_REQUESTED = "DELETE_USER_PROFILE_REQUESTED";
export const DELETE_USER_PROFILE_SUCCESS = "DELETE_USER_PROFILE_SUCCESS";
export const DELETE_USER_PROFILE_ERRORED = "DELETE_USER_PROFILE_ERRORED";

export const REGISTER_TENANT_REQUESTED = "REGISTER_CHANNEL_PARTNER_REQUESTED";
export const REGISTER_TENANT_SUCCESS = "REGISTER_CHANNEL_PARTNER_SUCCESS";
export const REGISTER_TENANT_ERRORED = "REGISTER_CHANNEL_PARTNER_ERRORED";

export const GET_ALL_TENANT_REQUESTED = "GET_ALL_CHANNEL_PARTNER_REQUESTED";
export const GET_ALL_TENANT_SUCCESS = "GET_ALL_CHANNEL_PARTNER_SUCCESS";
export const GET_ALL_TENANT_ERRORED = "GET_ALL_CHANNEL_PARTNER_ERRORED";

export const GET_TENANT_DETAILS_REQUESTED =
  "GET_CHANNEL_PARTNER_DETAILS_REQUESTED";
export const GET_TENANT_DETAILS_SUCCESS = "GET_CHANNEL_PARTNER_DETAILS_SUCCESS";
export const GET_TENANT_DETAILS_ERRORED = "GET_CHANNEL_PARTNER_DETAILS_ERRORED";

export const UPDATE_TENANT_DETAILS_REQUESTED =
  "UPDATE_CHANNEL_PARTNER_DETAILS_REQUESTED";
export const UPDATE_TENANT_DETAILS_SUCCESS =
  "UPDATE_CHANNEL_PARTNER_DETAILS_SUCCESS";
export const UPDATE_TENANT_DETAILS_ERRORED =
  "UPDATE_CHANNEL_PARTNER_DETAILS_ERRORED";

export const DELETE_TENANT_REQUESTED = "DELETE_CHANNEL_PARTNER_REQUESTED";
export const DELETE_TENANT_SUCCESS = "DELETE_CHANNEL_PARTNER_SUCCESS";
export const DELETE_TENANT_ERRORED = "DELETE_CHANNEL_PARTNER_ERRORED";

export const REGISTER_Orgunit_REQUESTED = "REGISTER_Orgunit_REQUESTED";
export const REGISTER_Orgunit_SUCCESS = "REGISTER_Orgunit_SUCCESS";
export const REGISTER_Orgunit_ERRORED = "REGISTER_Orgunit_ERRORED";

export const GET_ALL_Orgunit_REQUESTED = "GET_ALL_Orgunit_REQUESTED";
export const GET_ALL_Orgunit_SUCCESS = "GET_ALL_Orgunit_SUCCESS";
export const GET_ALL_Orgunit_ERRORED = "GET_ALL_Orgunit_ERRORED";

export const GET_Orgunit_DETAILS_REQUESTED = "GET_Orgunit_DETAILS_REQUESTED";
export const GET_Orgunit_DETAILS_SUCCESS = "GET_Orgunit_DETAILS_SUCCESS";
export const GET_Orgunit_DETAILS_ERRORED = "GET_Orgunit_DETAILS_ERRORED";

export const UPDATE_Orgunit_DETAILS_REQUESTED =
  "UPDATE_Orgunit_DETAILS_REQUESTED";
export const UPDATE_Orgunit_DETAILS_SUCCESS = "UPDATE_Orgunit_DETAILS_SUCCESS";
export const UPDATE_Orgunit_DETAILS_ERRORED = "UPDATE_Orgunit_DETAILS_ERRORED";

export const DELETE_Orgunit_REQUESTED = "DELETE_Orgunit_REQUESTED";
export const DELETE_Orgunit_SUCCESS = "DELETE_Orgunit_SUCCESS";
export const DELETE_Orgunit_ERRORED = "DELETE_Orgunit_ERRORED";

export const GET_ORG_PLAN_DETAILS_REQUESTED = "GET_ORG_PLAN_DETAILS_REQUESTED";
export const GET_ORG_PLAN_DETAILS_SUCCESS = "GET_ORG_PLAN_DETAILS_SUCCESS";
export const GET_ORG_PLAN_DETAILS_ERRORED = "GET_ORG_PLAN_DETAILS_ERRORED";

export const CREATE_OR_UPDATE_CONFIGS_REQUESTED =
  "CREATE_OR_UPDATE_CONFIGS_REQUESTED";
export const CREATE_OR_UPDATE_CONFIGS_SUCCESS =
  "CREATE_OR_UPDATE_CONFIGS_SUCCESS";
export const CREATE_OR_UPDATE_CONFIGS_ERRORED =
  "CREATE_OR_UPDATE_CONFIGS_ERRORED";

export const GET_ALL_ACTIVE_CONFIGS_REQUESTED =
  "GET_ALL_ACTIVE_CONFIGS_REQUESTED";
export const GET_ALL_ACTIVE_CONFIGS_SUCCESS = "GET_ALL_ACTIVE_CONFIGS_SUCCESS";
export const GET_ALL_ACTIVE_CONFIGS_ERRORED = "GET_ALL_ACTIVE_CONFIGS_ERRORED";

export const GET_ALL_DEPLOYED_CONFIGS_REQUESTED =
  "GET_ALL_DEPLOYED_CONFIGS_REQUESTED";
export const GET_ALL_DEPLOYED_CONFIGS_SUCCESS =
  "GET_ALL_DEPLOYED_CONFIGS_SUCCESS";
export const GET_ALL_DEPLOYED_CONFIGS_ERRORED =
  "GET_ALL_DEPLOYED_CONFIGS_ERRORED";

export const SET_ACTIVE_OUID_CONFIGS_REQUESTED =
  "SET_ACTIVE_OUID_CONFIGS_REQUESTED";
export const SET_ACTIVE_OUID_CONFIGS_SUCCESS =
  "SET_ACTIVE_OUID_CONFIGS_SUCCESS";
export const SET_ACTIVE_OUID_CONFIGS_ERRORED =
  "SET_ACTIVE_OUID_CONFIGS_ERRORED";

export const CREATE_APP_USER_REQUESTED = "CREATE_APP_USER_REQUESTED";
export const CREATE_APP_USER_SUCCESS = "CREATE_APP_USER_SUCCESS";
export const CREATE_APP_USER_ERRORED = "CREATE_APP_USER_ERRORED";

export const GET_APP_USER_DETAILS_REQUESTED = "GET_APP_USER_DETAILS_REQUESTED";
export const GET_APP_USER_DETAILS_SUCCESS = "GET_APP_USER_DETAILS_SUCCESS";
export const GET_APP_USER_DETAILS_ERRORED = "GET_APP_USER_DETAILS_ERRORED";

export const GET_ALL_APP_USER_REQUESTED = "GET_ALL_APP_USER_REQUESTED";
export const GET_ALL_APP_USER_SUCCESS = "GET_ALL_APP_USER_SUCCESS";
export const GET_ALL_APP_USER_ERRORED = "GET_ALL_APP_USER_ERRORED";

export const DELETE_APP_USER_REQUESTED = "DELETE_APP_USER_REQUESTED";
export const DELETE_APP_USER_SUCCESS = "DELETE_APP_USER_SUCCESS";
export const DELETE_APP_USER_ERRORED = "DELETE_APP_USER_ERRORED";
